import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { StoriesPageComponent } from './pages/stories-page/stories-page.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { StoryPageComponent } from './pages/story-page/story-page.component';
import { QuotesService } from './services/quotes.service';
import { StoriesService } from './services/stories.service';
import { StoryListItemComponent } from './components/story-list-item/story-list-item.component';
import { StoryInfoCardComponent } from './components/story-info-card/story-info-card.component';
import { PopupComponent } from './components/popup/popup.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    StoriesPageComponent,
    NavigationComponent,
    StoryPageComponent,
    StoryListItemComponent,
    StoryInfoCardComponent,
    PopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [
    QuotesService,
    StoriesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
