<ng-container *ngIf="isDownload; else normalSection">
  <div class="info-card">
    <h2 class="info-card__heading">Download</h2>
    <a
      [href]="download!.url"
      target="_blank"
      rel="noopener noreferrer"
      class="info-card__download"
      >{{ download!.filename }}</a
    >
  </div>
</ng-container>

<ng-template #normalSection>
  <div class="info-card">
    <h2 class="info-card__heading">{{ details!.heading }}</h2>
    <p class="info-card__small-text">{{ details!.subheading }}</p>
  </div>
</ng-template>
