<nav class="nav">
  <div class="nav__start">
    <a href="/" class="nav__item">Home</a>
    <a href="/stories" class="nav__item">Verhalen</a>
  </div>
  <div class="nav__end" (click)="onShowPopup()">
    <div
      class="unlock-sign"
      [ngClass]="
        correctPassword ? 'unlock-sign--unlocked' : 'unlock-sign--locked'
      "
    ></div>
    <ng-container *ngIf="correctPassword; else locked">
      <svg class="nav__item-icon">
        <use href="assets/img/sprites.svg#unlocked"></use>
      </svg>
    </ng-container>
    <ng-template #locked>
      <svg class="nav__item-icon">
        <use href="assets/img/sprites.svg#locked"></use>
      </svg>
    </ng-template>
  </div>
</nav>
<app-popup
  *ngIf="showPopup"
  (closeEvent)="onClosePopup($event)"
  (correctEvent)="onCorrectPassword($event)"
></app-popup>
