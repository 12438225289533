<div class="stories">
  <input
    class="stories__input"
    type="text"
    placeholder="Tiep een woord om te zoeken..."
    [formControl]="search"
  />

  <ng-container
    *ngIf="filteredStories.length > 0 || inputDetected; else noFilter"
  >
    <div class="heading-container">
      <h1 class="heading-container__title">Gefilterde verhalen</h1>
    </div>
    <div class="message" *ngIf="filteredStories.length == 0 && inputDetected">
      <img class="message__image" src="assets/img/nothing.svg" />
      <h2 class="message__heading">Geen verhalen gevonden</h2>
      <p class="message__text">
        Er zijn geen verhalen die voldoen aan jouw zoekopdracht.
      </p>
    </div>
    <ng-container *ngFor="let story of filteredStories">
      <app-story-list-item
        [story]="story"
        (click)="onReadStory(story.id)"
      ></app-story-list-item>
    </ng-container>
  </ng-container>

  <ng-template #noFilter>
    <div class="heading-container">
      <h1 class="heading-container__title">Ongelezen</h1>
    </div>
    <div class="message" *ngIf="unreadStories.length == 0">
      <img class="message__image" src="assets/img/done.svg" />
      <h2 class="message__heading">Alles gelezen</h2>
      <p class="message__text">
        Je hebt alle verhalen gelezen. Had je niks beters te doen?
      </p>
    </div>
    <ng-container *ngFor="let story of unreadStories">
      <app-story-list-item
        *ngIf="story.read == false"
        [story]="story"
        (click)="onReadStory(story.id)"
      ></app-story-list-item>
    </ng-container>

    <div class="heading-container">
      <h1 class="heading-container__title">Gelezen</h1>
    </div>
    <div class="message" *ngIf="readStories.length == 0">
      <img class="message__image" src="assets/img/reading.svg" />
      <h2 class="message__heading">Niks gelezen</h2>
      <p class="message__text">
        Je hebt nog geen verhalen gelezen, dus beter ga je lezen.
      </p>
    </div>
    <ng-container *ngFor="let story of readStories">
      <app-story-list-item
        *ngIf="story.read"
        [story]="story"
        (click)="onReadStory(story.id)"
      ></app-story-list-item>
    </ng-container>
  </ng-template>
</div>
