import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { StoriesPageComponent } from './pages/stories-page/stories-page.component';
import { StoryPageComponent } from './pages/story-page/story-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'stories', component: StoriesPageComponent },
  { path: 'stories/:id', component: StoryPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
