<div class="story-info">
  <svg
    class="info-icon info-icon--unread"
    [ngClass]="story.read ? 'info-icon--read' : 'info-icon--unread'"
  >
    <use href="assets/img/sprites.svg#checkmark"></use>
  </svg>
  <div class="overview">
    <p class="overview__subtitle">
      {{ story.comment }} / {{ story.details[0].heading }}
    </p>
    <p class="overview__title">{{ story.title }}</p>
  </div>
</div>
