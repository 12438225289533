import { Component, OnInit } from '@angular/core';
import { CryptoService } from 'src/app/services/crypto.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  showPopup: boolean = false;
  correctPassword: boolean = false;

  body: HTMLElement = document.body;
  nav: HTMLCollectionOf<Element> = document.getElementsByClassName("nav");
  home: HTMLCollectionOf<Element> = document.getElementsByClassName("home");
  story: HTMLCollectionOf<Element> = document.getElementsByClassName("story");
  stories: HTMLCollectionOf<Element> = document.getElementsByClassName("stories");

  constructor(
    private cryptoService: CryptoService,
    private localStorageService: LocalstorageService
  ) { }

  ngOnInit(): void {
    const pass = this.localStorageService.getPassword();
    if (pass != null) {
      this.correctPassword = this.cryptoService.checkPassword(pass);
    }
  }

  onShowPopup() {
    this.showPopup = true
    this.toggleClasses();
  }

  onClosePopup(valueEmitted: boolean){
    this.showPopup = valueEmitted;
    this.toggleClasses();
  }

  onCorrectPassword(valueEmitted: boolean){
    this.correctPassword = valueEmitted;
  }

  toggleClasses() {
    this.body.classList.toggle('noscroll');

    this.nav[0].classList.toggle('blur');

    if (this.home.length > 0) {
      this.home[0].classList.toggle('blur');
    }

    if (this.story.length > 0) {
      this.story[0].classList.toggle('blur');
    }

    if (this.stories.length > 0) {
      this.stories[0].classList.toggle('blur');
    }
  }
}
