import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
// import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Story } from 'src/app/models/story';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { StoriesService } from 'src/app/services/stories.service';
import { CryptoService } from 'src/app/services/crypto.service';

@Component({
  selector: 'app-stories-page',
  templateUrl: './stories-page.component.html',
  styleUrls: ['./stories-page.component.scss']
})
export class StoriesPageComponent implements OnInit {
  allStories: Story[] = [];
  readStories: Story[] = [];
  unreadStories: Story[] = [];
  filteredStories: Story[] = [];
  readingList: number[] = [];
  search: FormControl = new FormControl();
  loading: boolean = false;
  inputDetected: boolean = false;

  constructor(
    private cryptoService: CryptoService,
    private storiesService: StoriesService,
    private localStorageService: LocalstorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscribeOnSearch();

    this.allStories = this.storiesService.all;
    this.readingList = this.localStorageService.getReadingList();
    const passphrase = this.localStorageService.getPassword();

    this.allStories.forEach((story: Story) => {
      if (passphrase != null && this.cryptoService.checkPassword(passphrase)) {
        story.searchText = this.cryptoService.decrypt(story.text, passphrase);
      }
    });

    if (this.readingList.length > 0) {
      this.allStories.forEach((story: Story) => {
        if (this.readingList.includes(story.id)) {
          story.read = true;
          this.readStories.push(story);
        } else {
          this.unreadStories.push(story);
        }
      });
    } else {
      this.unreadStories = this.allStories;
    }

    this.readStories.sort((a, b) => a.title.localeCompare(b.title));
    this.unreadStories.sort((a, b) => a.title.localeCompare(b.title));
  }

  onReadStory(id: number) {
    this.router.navigate(['/stories', id]);
  }

  subscribeOnSearch() {
    // this.search.valueChanges.pipe(
    //   tap(() => this.loading = true),
    //   debounceTime(0),
    //   distinctUntilChanged()
    // ).subscribe((input: string) => this.onSearch(input));
    this.search.valueChanges.subscribe((input: string) => this.onSearch(input));
  }

  onSearch(input: string) {
    this.inputDetected = input.length > 0;

    this.filteredStories = [];

    if (input.length > 0) {
      this.allStories.forEach((story: Story) => {
        if (story.title.includes(input)) {
          this.filteredStories.push(story);
        } else {
          if (story.searchText.includes(input)) {
            this.filteredStories.push(story);
          }
        }
      });

      this.filteredStories.sort((a, b) => a.title.localeCompare(b.title));
    }

    this.loading = false;
  }
}
