<div class="story">
  <div class="heading-container">
    <h1 class="heading-container__title" [innerHTML]="story.title"></h1>
    <button class="button button--icon" (click)="toggleRead()">
      <svg
        class="read-icon"
        [ngClass]="story.read ? 'read-icon--read' : 'read-icon--unread'"
      >
        <use href="assets/img/sprites.svg#checkmark"></use>
      </svg>
    </button>
  </div>
  <div class="story__info">
    <app-story-info-card
      [isDownload]="true"
      [download]="story.download"
    ></app-story-info-card>
    <app-story-info-card
      *ngFor="let details of story.details"
      [isDownload]="false"
      [details]="details"
    ></app-story-info-card>
  </div>
  <ng-container *ngIf="unlocked; else locked">
    <div class="story-text" [innerHTML]="story.text"></div>
  </ng-container>
  <ng-template #locked>
    <div class="message">
      <img class="message__image" src="assets/img/secure.svg" />
      <h2 class="message__heading">Versleuteld</h2>
      <p class="message__text">Vul eerst het juiste wachtwoord in.</p>
    </div>
  </ng-template>
</div>
