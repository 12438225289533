import { Component, OnInit, Input } from '@angular/core';
import { StoryDetails } from 'src/app/models/story-details';
import { StoryDownload } from 'src/app/models/story-download';

@Component({
  selector: 'app-story-info-card',
  templateUrl: './story-info-card.component.html',
  styleUrls: ['./story-info-card.component.scss']
})
export class StoryInfoCardComponent implements OnInit {
  @Input() details?: StoryDetails = {
    heading: '',
    subheading: ''
  };

  @Input() download?: StoryDownload = {
    filename: '',
    url: ''
  };

  @Input() isDownload: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
