import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Story } from 'src/app/models/story';
import { CryptoService } from 'src/app/services/crypto.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { StoriesService } from 'src/app/services/stories.service';

@Component({
  selector: 'app-story-page',
  templateUrl: './story-page.component.html',
  styleUrls: ['./story-page.component.scss']
})
export class StoryPageComponent implements OnInit {
  story: Story = {
    id: 0,
    time: 0,
    download: {
      filename: '',
      url: ''
    },
    read: false,
    comment: '',
    details: [],
    title: '',
    text: '',
    searchText: ''
  };

  unlocked: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
    private storiesService: StoriesService,
    private localStorageService: LocalstorageService,
  ) { }

  ngOnInit(): void {
    const readingList = this.localStorageService.getReadingList();
    const passphrase = this.localStorageService.getPassword();

    this.route.params.subscribe(params => {
      this.story = this.storiesService.getStory(+params['id'])[0];

      if (readingList.includes(this.story.id)) {
        this.story.read = true;
      }

      if (passphrase != null && this.cryptoService.checkPassword(passphrase)) {
        this.unlocked = true;
        this.story.text = this.cryptoService.decrypt(this.story.text, passphrase);
        this.story.download.url = this.cryptoService.decrypt(this.story.download.url, passphrase);
      }
    });
  }

  toggleRead() {
    if (this.story.read) {
      this.localStorageService.removeStory(this.story.id);
    } else {
      this.localStorageService.addStory(this.story.id);
    }
    this.story.read = !this.story.read;
  }
}
