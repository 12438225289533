import { Quote } from '../models/quote';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  private quotes: Quote[] = [
    { name: 'Lex', text: 'Diepgaande verhalen met een manisch sausje. Perfect voor de regenachtige zondag!'}
  ]

  constructor() { }

  get all(): Quote[] {
    return this.quotes.slice();
  }
}
