import { Component, OnInit } from '@angular/core';

import { Quote } from '../../models/quote';
import { QuotesService } from 'src/app/services/quotes.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  quotes: Quote[] = [];
  id: number = 1;

  constructor(
    private quoteService: QuotesService
  ) { }

  ngOnInit(): void {
    this.quotes = this.quoteService.all;
    this.id = Math.floor(Math.random() * 10) + 1;
  }
}
