import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CryptoService } from 'src/app/services/crypto.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() correctEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  password: FormControl = new FormControl();
  correctPassword: boolean = false;

  constructor(
    private cryptoService: CryptoService,
    private localStorageService: LocalstorageService,
  ) { }

  ngOnInit(): void {
    this.subscribeOnPassword();

    const pass = this.localStorageService.getPassword();
    if (pass != null) {
      this.password.patchValue(pass);
    }
  }

  onClose() {
    this.closeEvent.emit(false);
  }

  subscribeOnPassword() {
    this.password.valueChanges.subscribe((input: string) => this.onPassword(input));
  }

  onPassword(input: string) {
    this.correctPassword = this.cryptoService.checkPassword(input);
    this.localStorageService.setPassword(input);
    this.correctEvent.emit(this.correctPassword);
  }
}
