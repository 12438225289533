import { Injectable } from '@angular/core';
import * as cryptojs from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  passwordHash: string = 'b741f2eda17b17253067cef70bb3f1685792ce5bdab479192dd65fde0b64de7e27ef623f77bacb126651cbfa0774cf902fbe38a0483b4bfbe2dc739dada8e643';

  constructor() { }

  public checkPassword(input: string): boolean {
    const hash = cryptojs.SHA3(input);
    const hashString = hash.toString(cryptojs.enc.Hex);

    return hashString === this.passwordHash;
  }

  public encrypt(value: string, passphrase: string) {
    const encrypted = cryptojs.AES.encrypt(value, passphrase).toString();
    console.log(encrypted);
  }

  public decrypt(value: string, passphrase: string): string {
    return cryptojs.AES.decrypt(value, passphrase).toString(cryptojs.enc.Utf8);
  }
}
