<div class="popup">
  <div class="popup-box">
    <h1 class="popup-box__header">Zonder wachtwoord geen verhalen</h1>
    <p class="popup-box__subheader">En zonder verhalen geen compleet leven.</p>
    <label for="password" class="popup-box__label">Wachtwoord</label>
    <input
      name="password"
      type="password"
      class="popup-box__input"
      [formControl]="password"
    />
    <div class="popup-box__buttons">
      <button class="button button--boring" (click)="onClose()">Terug</button>
      <button
        class="button"
        (click)="onClose()"
        [disabled]="!correctPassword"
        [ngClass]="
          correctPassword ? 'button--primary' : 'button--primary-washed'
        "
      >
        Ga verder
      </button>
    </div>
  </div>
</div>
