import { Component, OnInit, Input } from '@angular/core';
import { Story } from 'src/app/models/story';

@Component({
  selector: 'app-story-list-item',
  templateUrl: './story-list-item.component.html',
  styleUrls: ['./story-list-item.component.scss']
})
export class StoryListItemComponent implements OnInit {
  @Input() story: Story = {
    id: 0,
    time: 0,
    download: {
      filename: '',
      url: ''
    },
    details: [],
    read: false,
    comment: '',
    title: '',
    text: '',
    searchText: ''
  };

  constructor() { }

  ngOnInit(): void {
  }

}
