import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  stories: number[] = [];
  readingList: string | null = '';

  constructor() { }

  public setPassword(password: string) {
    localStorage.setItem('password', password);
  }

  public getPassword(): string | null {
    return localStorage.getItem('password');
  }

  public addStory(story: number) {
    this.readingList = localStorage.getItem('readingList');

    if (this.readingList != null) {
      this.stories = JSON.parse(this.readingList);
      if (!this.stories.includes(story)) {
        this.stories.push(story);
      }
    } else {
      this.stories.push(story);
    }

    this.readingList = JSON.stringify(this.stories);

    localStorage.setItem('readingList', this.readingList);
  }

  public removeStory(story: number) {
    this.readingList = localStorage.getItem('readingList');

    if (this.readingList != null) {
      this.stories = JSON.parse(this.readingList);
      this.stories = this.stories.filter(item => item !== story);
      this.readingList = JSON.stringify(this.stories);
      localStorage.setItem('readingList', this.readingList);
    }
  }

  public getReadingList(): number[] {
    this.readingList = localStorage.getItem('readingList');

    if (this.readingList != null) {
      this.stories = JSON.parse(this.readingList);
    }

    return this.stories;
  }

  public clear() {
    localStorage.clear();
  }
}
