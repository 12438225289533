<div class="home">
  <div class="heading">
    <h1 class="heading-title">Prachtige teksten van prachtige mensen</h1>
    <h2 class="heading-subtitle">of gewoon een beetje pilaaaa</h2>
    <div class="heading-buttons">
      <a href="/stories" class="button button--primary">Verhalen overzicht</a>
      <a href="/stories/{{ id }}" class="button button--blanco"
        >Random verhaal</a
      >
    </div>
  </div>
</div>
